<template>
  <div id="property-details" class="mb-4">
    <v-container fluid>
      <v-row justify="center" align-sm="center">
        <v-col cols="12" sm="12">
          <div class="rounded-lg title-bg py-2 px-4">
            <h3 class="white--text">Property Details</h3>
          </div>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar" :color="snackbar_color" rounded="pill" top>
        {{ text }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" icon v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-row class="mt-1">
        <v-col cols="12" md="4" xl="4" sm="12">
          <template>
            <v-card>
              <v-carousel
                cycle
                height="auto"
                hide-delimiter-background
                show-arrows-on-hover
              >
                <v-carousel-item v-for="photo in propertyPhoto" :key="photo.id">
                  <v-img
                    :src="
                      imgURL +
                      '/' +
                      photo.user_id +
                      '/' +
                      photo.basic_property_id +
                      '/' +
                      photo.image_name
                    "
                    width="auto"
                    alt="100 miles realtors property image"
                  />
                </v-carousel-item>
              </v-carousel>
            </v-card>
          </template>
        </v-col>
        <v-col cols="12" md="8" xl="8" sm="12">
          <v-row class="py-2 blue-grey lighten-5">
            <span v-for="property in basicDetails" :key="property.id">
              <div class="text-left blue--text darken-4 ml-2">
                <b
                  >{{ property.propertytype.property_name }} for
                  {{ property.pfor }} in {{ property.city }},
                  {{ property.state.state_name }}</b
                >
              </div>

              <!-- <b>{{ property.basic_property_id }}</b>-->
            </span>
          </v-row>

          <span v-for="property in basicDetails" :key="property.id">
            <v-row class="mt-4">
              <v-col cols="6" md="4" lg="4" sm="6" class="">
                <span class="caption blue-grey--text darken-4">
                  Property Type:
                </span>
                <br />
                <span class="subtitle-2">
                  {{ property.ptype }}
                </span>
              </v-col>

              <v-col cols="6" md="4" lg="4" sm="6" class="">
                <span class="caption blue-grey--text darken-4">
                  Possession Status:
                </span>
                <br />
                <span class="subtitle-2">
                  {{ property.possession }}
                </span>
              </v-col>

              <v-col
                cols="6"
                md="4"
                lg="4"
                sm="12"
                v-if="property.p_time != null"
                class=""
              >
                <span class="caption blue-grey--text darken-4">
                  Available From:
                </span>
                <br />
                <span class="subtitle-2">
                  {{ property.p_time }}
                </span>
              </v-col>

              <v-col
                cols="6"
                md="4"
                lg="4"
                sm="12"
                v-if="property.p_age != null"
                class=""
              >
                <span class="caption blue-grey--text darken-4">
                  Property Age:
                </span>
                <br />
                <span class="subtitle-2">
                  {{ property.p_age }}
                </span>
              </v-col>

              <!-- <b>{{ property.district.city_name }}</b> -->
              <v-col cols="6" class="">
                <span class="caption blue-grey--text darken-4"> Price : </span>
                <br />
                <span class="subtitle-2">
                  {{ property.price }}
                </span>
              </v-col>

              <v-col cols="6" md="4" lg="4" sm="6" class="">
                <span class="caption blue-grey--text darken-4">
                  Price Negotiable:
                </span>
                <br />
                <span class="subtitle-2">
                  <p v-if="property.price_negotiable == 1">Yes</p>
                  <p v-else>No</p>
                </span>
              </v-col>

              <v-col cols="6" md="4" lg="4" sm="12" class="">
                <span class="caption blue-grey--text darken-4">
                  Description :
                </span>
                <br />
                <span class="subtitle-2">
                  {{ property.description }}
                </span>
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>

            <!-- APRTMENT & STUDIO APARTMENT -->
            <div
              v-if="
                property.property_type_id == 1 || property.property_type_id == 6
              "
            >
              <v-row>
                <v-col cols="6" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Super Builtup Area :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.super_built_area }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Builtup Area :
                  </span>
                  <br />
                  <span class="caption subtitle-2">
                    {{ property.built_area }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Carpet Area :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.carpet_area }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Total Floor :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.total_no_floor }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Floor No.:
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.floor_no }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Facing :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.facing }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Bedroom:
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.bedroom }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Bathroom :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.bathroom }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Balcony :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.balcony }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Maintenance :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.maintenance }}
                  </span>
                </v-col>
              </v-row>
            </div>
            <!-- FARM LAND -->
            <div v-if="property.property_type_id == 7" class="mt-2">
              <v-row>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Area in Hectare :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.hectare }}
                  </span>
                </v-col>
              </v-row>
            </div>
            <!-- Farm House -->
            <div v-if="property.property_type_id == 8">
              <v-row>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Area in Hectare :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.hectare }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Builtup Area :
                  </span>
                  <br />
                  <span class="caption subtitle-2">
                    {{ property.built_area }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Carpet Area :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.carpet_area }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Total Floor :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.total_no_floor }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Facing :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.facing }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Bedroom:
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.bedroom }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Bathroom :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.bathroom }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Balcony :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.balcony }}
                  </span>
                </v-col>
              </v-row>
            </div>
            <!-- Row House/Bungalow/ villa /pent house-->
            <div
              v-if="
                property.property_type_id == 2 ||
                property.property_type_id == 3 ||
                property.property_type_id == 4 ||
                property.property_type_id == 5
              "
            >
              <v-row>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Builtup Area :
                  </span>
                  <br />
                  <span class="caption subtitle-2">
                    {{ property.built_area }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Carpet Area :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.carpet_area }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Total Floor :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.total_no_floor }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Facing :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.facing }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Bedroom:
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.bedroom }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Bathroom :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.bathroom }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Balcony :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.balcony }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Plot Area :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.plot_area }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Plot Width :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.p_width }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Plot Length :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.p_length }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Road Width :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.road_width }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Open Sides :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.open_side }}
                  </span>
                </v-col>
              </v-row>
            </div>
            <!--Plot Property-->
            <div
              v-if="
                property.property_type_id == 13 ||
                property.property_type_id == 15 ||
                property.property_type_id == 17
              "
            >
              <v-row>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Facing :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.facing }}
                  </span>
                </v-col>

                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Plot Area :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.plot_area }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Plot Width :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.p_width }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Plot Length :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.p_length }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Road Width :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.road_width }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Open Sides :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.open_side }}
                  </span>
                </v-col>

                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Compound:
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.compound }}
                  </span>
                </v-col>
              </v-row>
            </div>
            <!--Office/shop/showroom Property-->
            <div
              v-if="
                property.property_type_id == 9 ||
                property.property_type_id == 10 ||
                property.property_type_id == 11 ||
                property.property_type_id == 12
              "
            >
              <v-row>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Super Builtup Area :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.super_built_area }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Builtup Area :
                  </span>
                  <br />
                  <span class="caption subtitle-2">
                    {{ property.built_area }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Carpet Area :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.carpet_area }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Total Floor :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.total_no_floor }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Floor No.:
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.floor_no }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Facing :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.facing }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Cafeteria :
                  </span>
                  <br />
                  <span class="subtitle-2" v-if="property.cafeteria == 0">
                    No
                  </span>
                  <span class="subtitle-2" v-else> Yes </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Maintenance :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.maintenance }}
                  </span>
                </v-col>
                <v-col
                  cols="6"
                  md="4"
                  lg="4"
                  sm="12"
                  class=""
                  v-if="
                    property.property_type_id == 11 ||
                    property.property_type_id == 12
                  "
                >
                  <span class="caption blue-grey--text darken-4">
                    Corner Property :
                  </span>
                  <br />
                  <span class="subtitle-2" v-if="property.corner_property == 0">
                    No
                  </span>
                  <span class="subtitle-2" v-else> Yes </span>
                </v-col>
                <v-col
                  cols="6"
                  md="4"
                  lg="4"
                  sm="12"
                  class=""
                  v-if="
                    property.property_type_id == 11 ||
                    property.property_type_id == 12
                  "
                >
                  <span class="caption blue-grey--text darken-4">
                    Road Facing :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.road_facing }}
                  </span>
                </v-col>
              </v-row>
            </div>

            <!-- WAREHOUSE AND INDUSTRIAL BUILDING -->
            <div
              v-if="
                property.property_type_id == 14 ||
                property.property_type_id == 16
              "
            >
              <v-row>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Builtup Area :
                  </span>
                  <br />
                  <span class="caption subtitle-2">
                    {{ property.built_area }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Carpet Area :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.carpet_area }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Facing :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.facing }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Plot Area :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.plot_area }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Plot Width :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.p_width }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Plot Length :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.p_length }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Open Sides :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.open_side }}
                  </span>
                </v-col>
                <v-col cols="6" md="4" lg="4" sm="6" class="">
                  <span class="caption blue-grey--text darken-4">
                    Road Width :
                  </span>
                  <br />
                  <span class="subtitle-2">
                    {{ property.road_width }}
                  </span>
                </v-col>
              </v-row>
            </div>
          </span>
        </v-col>
      </v-row>
      <v-divider class="my-2"></v-divider>
      <span>
        <v-row class="">
          <v-col cols="6" v-for="amenity in amenityDetails" :key="amenity.id">
            <span class="subtitle-2 blue-grey--text darken-4"
              >{{ amenity.amenitytype.amenity_type }} </span
            ><br />
            <v-chip class="ma-2" color="primary" small outlined>
              {{ amenity.amenitytype.amenity_name }}
            </v-chip>
            <br />
          </v-col>
        </v-row>
      </span>

      <v-row>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on">
            Click Me
          </v-btn>
        </template>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Property from "../../api/Property";
import EnquiryFormMixins from "../../mixins/EnquiryFormMixins";
export default {
  name: "property-details",
  props: ["id"],
  mixins: [EnquiryFormMixins],
  components: {},
  data() {
    return {
      valid: true,
      imgURL: "https://100milesrealtors.com/routes/storage/images",
    };
  },
  computed: {
    ...mapState({
      basicDetails: (state) => state.property.basicDetails,
      propertyPhoto: (state) => state.property.propertyPhoto,
      amenityDetails: (state) => state.property.amenityDetails,
    }),
  },
  mounted() {
    this.showPropertyDetails();
  },
  methods: {
    ...mapActions(["viewPropertyDetails"]),
    validate() {
      this.$refs.form.validate();
    },
    showPropertyDetails() {
      this.viewPropertyDetails({
        propertyId: this.id,
      });
      console.log("this.id :", this.id);
    },
    async postEnquiry() {
      this.loading = true;
      console.log(this.basicDetails[0].basic_property_id);
      const fd = new FormData();
      fd.append("name", this.enquiry.name);
      fd.append("contact", this.enquiry.contact);
      fd.append("city", this.enquiry.city);
      fd.append("enquiry", this.enquiry.enquiryText);
      fd.append("basic_property_id", this.basicDetails[0].basic_property_id);
      await Property.postEnquiry(fd)
        .then((response) => {
          if (response.status == 201) {
            this.loading = false;
            this.text = response.data.message;
            this.snackbar = true;
            this.snackbar_color = "success";
            console.log(response.data);
            this.clearAllField();
          }
        })
        .catch(function (error) {
          this.loading = false;
          console.log("Error message :", error.message);
        });
    },
  },
};
</script>
<style scoped></style>
